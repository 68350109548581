<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1" />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('successEmailValidation.successTitle') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ this.successMsg }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          />
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" />  {{ $t('successEmailValidation.btn') }}
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    ValidationObserver,
    ToastificationContent,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      successMsg: null,
    }
  },
  created() {
    if (this.$route.params.token) {
      this.verifyEmailMethod(this.$route.params.token).then(response => {
        this.successMsg = response.data.message
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$t('faildEmailValidation.successTitle')}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: response.data.message,
          },
        })
        this.$router.push({ name: 'auth-login' })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$t('faildEmailValidation.successTitle')}`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        })
        this.successMsg = error.response.data.messge
      })
    } else {
      this.successMsg = this.$t('faildEmailValidation.successTitle')
    }
  },
  methods: {
    ...mapActions({
      verifyEmailMethod: 'auth/verifyEmail',
    }),

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'auth-reset-password-v1' })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
